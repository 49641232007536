import React, { useState } from 'react';
import plantumlEncoder from 'plantuml-encoder';
import Footer from './Footer';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

import { useAuth0 } from '@auth0/auth0-react';

//import '../index.css';
//import Profile from './Profile';
import { Configuration, OpenAIApi } from 'openai';



import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
} from '@chakra-ui/react';

const configuration = new Configuration({
  apiKey: '', // Provide an initial default value, or remove it altogether
});
const openai = new OpenAIApi(configuration);


const diagramTypes = [
  'Class Diagram',
  'Use Case Diagram',
  'Sequence Diagram',
  'Activity Diagram',
  'State Machine Diagram',
  'Component Diagram',
  'Deployment Diagram',
  'Object Diagram',
  'Package Diagram',
  'Communication Diagram',
  'Composite Structure Diagram',
  'Interaction Overview Diagram',
  'Timing Diagram',
  'Profile Diagram',
  'Package Merge Diagram',
  'Component Internal Diagram',
  'Entity Relationship Diagram (ERD)',
];

const firebaseConfig = {
  apiKey: 'AIzaSyCFQw62MspR946KONnv856YY2IMN2DC-DQ',
  authDomain: 'umlifyai.firebaseapp.com',
  projectId: 'umlifyai',
  storageBucket: 'umlifyai.appspot.com',
  messagingSenderId: '682250738685',
  appId: '1:682250738685:web:0001c60f3a715765445794',
  measurementId: 'G-7DNVV8Y6K6',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


function Dashboard() {
  const [diagram, setDiagram] = useState('');
  const [scenario, setScenario] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imageError, setImageError] = useState('');
  const [selectedDiagram, setSelectedDiagram] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dataError, setDataError] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [apiKey, setApiKey] = useState('');

  const avatars = [
    {
      name: 'React JS',
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcTctlFGaNUoySqfDcvncevA0xwZqLM4QIWw&usqp=CAU',
    },
    {
      name: 'Segun Adebayo',
      url: 'https://acv.engineering/images/open-ai.png',
    },
    {
      name: 'Kent Dodds',
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxCy71Sv9IgnMaec5HTX0uco6j4X-SnfpYXopCD1k&s',
    },
  ];

  const { user, isAuthenticated } = useAuth0();

  const handleGenerateImage = async () => {
    
    setIsLoading(true);
    if (scenario && selectedDiagram && apiKey) {
      try {
        let promptText = `generate a detailed ${selectedDiagram} - PlantUML code for ${scenario}`;
        const configuration = new Configuration({
          apiKey: apiKey,
        });
        const openai = new OpenAIApi(configuration);

        // Modify the prompt based on the selected diagram type
        switch (selectedDiagram) {
          case 'Class Diagram':
            // Code generation for Class Diagram
            promptText = `${promptText}`;
            break;
          case 'Use Case Diagram':
            // Code generation for Use Case Diagram
            promptText = `${promptText}`;
            break;
          case 'Sequence Diagram':
            // Code generation for Sequence Diagram
            promptText = `${promptText}`;
            break;
          case 'Activity Diagram':
            // Code generation for Activity Diagram
            promptText = `${promptText}`;
            break;
          case 'State Machine Diagram':
            // Code generation for State Machine Diagram
            promptText = `${promptText}`;
            break;
          case 'Component Diagram':
            // Code generation for Component Diagram
            promptText = `${promptText}`;
            break;
          case 'Deployment Diagram':
            // Code generation for Deployment Diagram
            promptText = `${promptText}`;
            break;
          case 'Object Diagram':
            // Code generation for Object Diagram
            promptText = `${promptText}`;
            break;
          case 'Package Diagram':
            // Code generation for Package Diagram
            promptText = `${promptText}`;
            break;
          case 'Communication Diagram':
            // Code generation for Communication Diagram
            promptText = `${promptText}`;
            break;
          case 'Composite Structure Diagram':
            // Code generation for Composite Structure Diagram
            promptText = `${promptText}`;
            break;
          case 'Interaction Overview Diagram':
            // Code generation for Interaction Overview Diagram
            promptText = `${promptText}`;
            break;
          case 'Timing Diagram':
            // Code generation for Timing Diagram
            promptText = `${promptText}`;
            break;
          case 'Profile Diagram':
            // Code generation for Profile Diagram
            promptText = `${promptText}`;
            break;
          case 'Package Merge Diagram':
            // Code generation for Package Merge Diagram
            promptText = `${promptText}`;
            break;
          case 'Component Internal Diagram':
            // Code generation for Component Internal Diagram
            promptText = `${promptText}`;
            break;
          case 'Entity Relationship Diagram (ERD)':
            // Code generation for ERD
            promptText = `${promptText}`;
            break;
          default:
            break;
        }

        const response = await openai.createCompletion({
          model: 'text-davinci-003',
          prompt: promptText,
          max_tokens: 4000,
          temperature: 0.1,
        });

        const generatedCode = response.data.choices[0].text.trim();
        setDiagram(generatedCode);
        setGeneratedCode(generatedCode);

        const encoded = plantumlEncoder.encode(generatedCode);

        const imageResponse = await fetch(
          'https://www.plantuml.com/plantuml/png/' + encoded
        );

        if (!imageResponse.ok) {
          throw new Error('Error generating image');
        }

        const blob = await imageResponse.blob();
        const imageURL = URL.createObjectURL(blob);
        setImageURL(imageURL);
        setImageError('');

       

        // Save the diagram data to Firebase Firestore
        const docRef = await addDoc(collection(db, 'diagrams'), {
          plantUML: generatedCode,
          scenario,
          apiKey : apiKey,
          userAvatar: user.picture,
          userNickname: user.nickname,
          userSub: user.sub,
          userUpdatedAt: user.updated_at,
          userEmail: user.email,
          diagramType: selectedDiagram,
          diagramURL: imageResponse.url,
          status: 'Saved to Firebase',
        });

        


       // console.log('Document written with ID: ', docRef.id);


      } catch (error) {
         const docRef = await addDoc(collection(db, 'diagrams'), {
           plantUML: generatedCode,
           scenario,
           apiKey: apiKey,
           userAvatar: user.picture,
           userNickname: user.nickname,
           userSub: user.sub,
           userUpdatedAt: user.updated_at,
           userEmail: user.email,
           diagramType: selectedDiagram,

           status: 'Error generating image',
         });

         //console.log('Document written with ID: ', docRef.id);
        console.error(error);
        setImageError('Error generating image');
      }
    } else {
       const docRef = await addDoc(collection(db, 'diagrams'), {
         plantUML: generatedCode,
         scenario,
         apiKey: apiKey,
         userAvatar: user.picture,
         userNickname: user.nickname,
         userSub: user.sub,
         userUpdatedAt: user.updated_at,
         userEmail: user.email,
         diagramType: selectedDiagram,
         status:
           'Error generating image - Please enter the scenario, API Key and select a diagram type',
       });

       //console.log('Document written with ID: ', docRef.id);
      setDataError('Please enter the scenario, API Key and select a diagram type');
    }

    setIsLoading(false);
  };

  
  const handleDownloadImage = () => {
  setIsLoading(true);
  if (imageURL) {
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = `${selectedDiagram}.png`;
    link.click();
    window.location.reload();
  }
  setIsLoading(false);
};



  const handleScenarioChange = event => {
    setScenario(event.target.value);
  };

  const handleDiagramTypeChange = event => {
    setSelectedDiagram(event.target.value);
  };

  return (
    <>
      <Box position={'relative'}>
        <Container
          as={SimpleGrid}
          maxW={'7xl'}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 32 }}
        >
          <Stack spacing={{ base: 10, md: 20 }}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
            >
              OpenAI{' '}
              <Text
                as={'span'}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                &
              </Text>{' '}
              PlantUML
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: '3xl', sm: '3xl' }}>
              Generate stunning UML diagrams from plain English text using AI
            </Text>

            <Stack direction={'row'} spacing={4} align={'center'}>
              <AvatarGroup>
                {avatars.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    size={'xl'}
                    position={'relative'}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: 'full',
                      height: 'full',
                      rounded: 'full',
                      transform: 'scale(1.125)',
                      bgGradient: 'linear(to-bl, red.400,pink.400)',
                      position: 'absolute',
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Text
                fontFamily={'heading'}
                fontSize={{ base: '4xl', md: '6xl' }}
              >
                +
              </Text>
              <Flex
                align={'center'}
                justify={'center'}
                fontFamily={'heading'}
                fontSize={{ base: 'sm', md: 'lg' }}
                bg={'gray.800'}
                color={'white'}
                rounded={'full'}
                minWidth={useBreakpointValue({ base: '44px', md: '60px' })}
                minHeight={useBreakpointValue({ base: '44px', md: '60px' })}
                position={'relative'}
                _before={{
                  content: '""',
                  width: 'full',
                  height: 'full',
                  rounded: 'full',
                  transform: 'scale(1.125)',
                  bgGradient: 'linear(to-bl, orange.400,yellow.400)',
                  position: 'absolute',
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              >
                YOU
              </Flex>
            </Stack>
          </Stack>
          <Stack
            bg={'gray.50'}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: 'lg' }}
          >
            <Stack spacing={4}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              >
                UMLifyAI
                <Text
                  as={'span'}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  bgClip="text"
                >
                  !
                </Text>
              </Heading>
              <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                Generate stunning UML diagrams from plain English text using AI
              </Text>
            </Stack>

            <Box as={'form'} mt={10}>
              <Stack spacing={4}>
                <FormControl id="scenario">
                  <FormLabel>
                    Scenario<span style={{ color: 'red' }}>*</span>{' '}
                  </FormLabel>
                  <Textarea
                    placeholder="Enter the scenario"
                    value={scenario}
                    onChange={handleScenarioChange}
                  />
                  <FormHelperText>
                    Please provide a brief description of the scenario you want
                    to generate UML for. This can be a problem statement, a
                    question, or any context that you want the generated UML to
                    be based on.
                  </FormHelperText>
                </FormControl>
                <FormControl id="apiKey">
                  <FormLabel>
                    OpenAI API Key<span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                  <Input
                    type="password"
                    placeholder="Enter your OpenAI API key"
                    value={apiKey}
                    onChange={(event) => setApiKey(event.target.value)}
                  />
                  <FormHelperText>
                    Please enter your OpenAI API key. If you don't have an API
                    key, you can obtain one by following these steps:
                    <ol style={{ paddingLeft: '20px' }}>
                      <li>
                        Go to the{' '}
                        <a
                          href="https://www.openai.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          OpenAI website
                        </a>
                        .
                      </li>
                      <li>
                        Sign in to your OpenAI account or create a new account.
                      </li>
                      <li>
                        Navigate to the API section and follow the instructions
                        to obtain an API key.
                      </li>
                    </ol>
                    <Box mt={2}>
                      <strong>Note:</strong> The API key is required to access
                      the OpenAI API and generate UML diagrams.
                    </Box>
                  </FormHelperText>
                </FormControl>

                <FormControl id="diagramType">
                  <FormLabel>
                    Diagram Type <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                  <Select
                    placeholder="Select diagram type"
                    value={selectedDiagram}
                    onChange={handleDiagramTypeChange}
                  >
                    {diagramTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={handleGenerateImage}
                  disabled={isLoading} // Disable the button when isLoading is true
                >
                  {isLoading ? <Spinner color="white" /> : 'Generate Diagram'}
                </Button>

                {dataError && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle></AlertTitle>
                    <AlertDescription>{dataError}</AlertDescription>
                  </Alert>
                )}

                {imageURL && (
                  <Button
                    fontFamily={'heading'}
                    mt={8}
                    w={'full'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color={'white'}
                    _hover={{
                      bgGradient: 'linear(to-r, red.400,pink.400)',
                      boxShadow: 'xl',
                    }}
                    onClick={handleDownloadImage}
                  >
                    Download Diagram
                  </Button>
                )}

                {imageError ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Insufficient Prompt!</AlertTitle>
                    <AlertDescription>{imageError}</AlertDescription>
                  </Alert>
                ) : imageURL ? (
                  <div>
                    <Alert status="success">
                      <AlertIcon />
                      Data uploaded to the server. Fire on!
                    </Alert>

                    <img
                      className="image"
                      src={imageURL}
                      alt="PlantUML Diagram"
                      style={{
                        maxWidth: '100%',
                        width: '100%',
                        height: 'auto',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        marginTop: '10px',
                      }}
                    />
                  </div>
                ) : null}
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default Dashboard;
