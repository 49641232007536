import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@chakra-ui/react';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <Button
        rounded="full"
        size="xl"
        fontWeight="bold"
        px={6}
        colorScheme="red"
        bg="red.400"
        _hover={{ bg: 'red.500' }}
        onClick={() => loginWithRedirect()}
      >
        Sign In
      </Button>
    )
  );
};

export default LoginButton;
