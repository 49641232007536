import { useAuth0 } from '@auth0/auth0-react';
import { Box, Flex, Heading, Button ,Text} from '@chakra-ui/react';

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();
  const { user } = useAuth0();
  return (
    isAuthenticated && (
          <Button
            variant="outline"
            colorScheme="white"
            onClick={() => logout()}
          >
            Log Out
          </Button>
    
      
    )
  );
};

export default LogoutButton;
