import { ReactElement } from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react';
import {  FaAudible ,FaWhmcs,FaAlignLeft } from 'react-icons/fa';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack alignItems={'center'}> 
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'black'}
        mb={1}
        alignItems={'center'}
        
      >
        {icon}
      </Flex>
      <Text fontWeight={900} fontSize={'lg'}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Box p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={FaAudible} w={10} h={10} />}
          title={'AI-powered UML Generation'}
          text={
            'UMLiyiAI leverages advanced AI algorithms to automatically generate UML diagrams. By analyzing code or textual descriptions, it accurately translates complex concepts into visual representations, reducing manual effort and ensuring precise and consistent UML diagrams.'
          }
        />
        <Feature
          icon={<Icon as={FaWhmcs} w={10} h={10} />}
          title={'Seamless Integration with PlantUML'}
          text={
            "UMLiyiAI seamlessly integrates with PlantUML, enabling users to harness the power of AI within the familiar PlantUML environment. It enhances PlantUML's capabilities by providing an AI-driven UML generation feature, saving time and improving the overall diagramming experience."
          }
        />
        <Feature
          icon={<Icon as={FaAlignLeft} w={10} h={10} />}
          title={'Scenario Generation as UML'}
          text={
            'UMLiyiAI offers a unique capability of transforming scenarios into UML diagrams. By inputting textual or visual scenarios, it intelligently converts them into UML representations, allowing for a dynamic and interactive modeling approach, facilitating better communication and understanding of system behavior.'
          }
        />
      </SimpleGrid>
    </Box>
  );
}
