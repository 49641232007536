import Dashboard from "./Dashboard";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from '@auth0/auth0-react';
import Home from "./Home";
import Footer from "./Footer";
import Features from "./Features";
import { Container } from "@chakra-ui/react";

import Pricing from "./Pricing";
import Navbar from "./Navbar";
function App() {
  const { user, isAuthenticated } = useAuth0();
  



  return (
    <div className="App">
      <header className="App-header">
        {isAuthenticated ? (
          <>
          <Navbar />
            <Dashboard />
          </>
        ) : (
          <>
            <Container
              maxW={'7xl'}
              columns={{ base: 1, md: 2 }}
              spacing={{ base: 10, lg: 32 }}
              py={{ base: 10, sm: 20, lg: 32 }}
            >
              <Home />
            <Features />
           
            {/* <Pricing /> */}
            </Container>


            <Footer />
          </>
        )}
      </header>
    </div>
  );
}

export default App;
